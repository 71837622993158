import { Modal, Spinner, Accordion, Card, Button } from "react-bootstrap"
import "./index.css"

export type LoadingProps = {
  /** Default: true */
  isOpen?: boolean
  /** Default: My dialog */
  contentLabel?: string
  /** Default: false */
  ariaHideApp?: boolean
  /** Default: popup */
  className?: string
  /** Default: modal */
  portalClassName?: string
}

export default function Loading({ isOpen, contentLabel, ariaHideApp, className, portalClassName }: LoadingProps) {
  isOpen ??= true
  contentLabel ??= "My dialog"
  ariaHideApp ??= false
  className ??= "popup-loader"
  portalClassName ??= "modal"
  return (
    <Modal show={isOpen} onHide={() => {}} centered>
      <div className="loading loading--full-height">
        <span className="loader"></span>
      </div>
    </Modal>
  )
}
