import React from "react";

const Footer: React.FC = () => {
    return (
        <div className="footer" style={{ backgroundColor: "#006241" }}>
            <ul className="list-inline footerNav">
                <li>
                    <a style={{ color: "white" }} href="https://www.sobeys.com/en/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                        Terms of Use
                    </a>
                </li>
                <li>
                    <a style={{ color: "white" }} href="https://www.sobeys.com/en/privacy-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </li>
                <li>
                    <a style={{ color: "white" }} href="/rules" target="_blank" rel="noopener noreferrer">
                        Rules & Regulations
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Footer;
