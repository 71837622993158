import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
    return (
        <header>
            <Link to="/">
                <img src="/assets/images/logo.png" alt="Logo" className="Logo" width={320} />
            </Link>
        </header>
    );
};

export default Header;
