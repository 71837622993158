const Rules = () => {
    return (
        <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                <div className="content" style={{ paddingBottom: "30px" }}>
                    <div style={{ textAlign: "center", marginBottom: "30px" }}>
                        <h1>CONTEST RULES</h1>
                        <p className="thanks_head">2024 Sobeys Contest</p>
                        <p>(the “Contest”)</p>
                    </div>

                    <>
                        <ol>
                            <li>
                                <strong>Eligibility</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest is open only to legal residents of Ontario (the “Participating Province”) who have reached the age of majority on the date that they entered
                            the Contest. Void where prohibited by law. Employees, directors, officers, representatives, agents of Sobeys Capital Incorporated (the “Sponsor”), XMC
                            Experiential Marketing (the “Administrator”), and each of their respective parent companies, affiliates, franchisees, subsidiaries, distributors,
                            representatives, advertising and promotional agencies, agents, sponsors, and any entity that they contract with specific to the&nbsp;Contest, as well as
                            the immediate family members, and any persons domiciled with any of the above (whether related or not), are not eligible to win a Prize.
                        </p>
                        <ol start={2}>
                            <li>
                                <strong>Contest Period</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest runs from 5:30pm eastern time (“ET”) on June 8, 2024 and ends 8:30pm ET on August 22, 2024 (the "Contest Period"). Prizes will be drawn
                            during two (2) draw periods as outlined in the table below (each a “Draw Period”).
                        </p>
                        <table className="tableWithBorder marginBottom">
                            <tbody>
                                <tr>
                                    <td width={104}>
                                        <p>
                                            <strong>Draw Period #</strong>
                                        </p>
                                    </td>
                                    <td width={126}>
                                        <p>
                                            <strong>Contest Period </strong>
                                        </p>
                                    </td>
                                    <td width={82}>
                                        <p>
                                            <strong># of Prizes Available</strong>
                                        </p>
                                    </td>
                                    <td width={189}>
                                        <p>
                                            <strong>Prize Description</strong>
                                        </p>
                                    </td>
                                    <td width={123}>
                                        <p>
                                            <strong>Draw Date</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={104}>
                                        <p>Draw Period 1</p>
                                    </td>
                                    <td width={126}>
                                        <p>June 8, 2024 - July 6, 2024</p>
                                    </td>
                                    <td width={82}>
                                        <p>1</p>
                                    </td>
                                    <td width={189}>
                                        <p>One (1) $1,000 Ticketmaster® Gift Card</p>
                                    </td>
                                    <td width={123}>
                                        <p>July 8, 2024</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={104}>
                                        <p>Draw Period 2</p>
                                    </td>
                                    <td width={126}>
                                        <p>July 7, 2024 - August 22, 2024</p>
                                    </td>
                                    <td width={82}>
                                        <p>1</p>
                                    </td>
                                    <td width={189}>
                                        <p>One (1) $1,000 Ticketmaster® Gift Card</p>
                                    </td>
                                    <td width={123}>
                                        <p>August 23, 2024</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ol start={3}>
                            <li>
                                <strong>How to Enter without Purchase</strong>
                            </li>
                        </ol>
                        <p />
                        <p>
                            NO PURCHASE IS NECESSARY. To enter the contest, visit the Sponsor booth (the “Booth”) placed at the Budweiser Stage, 909 Lake Shore Blvd W, Toronto, ON
                            M6K 3L3, during the Contest Period. Each entrant visiting the Booth and wishing to enter the Contest can scan the QR code with their own mobile device
                            or use the device that will be provided by a representative of the Sponsor or Administrator while onsite and complete the official Contest entry form
                            (the “Entry Form”) by providing your e-mail, first name, last name, postal code, mobile number, check off the corresponding box on the form to opt-in to
                            the digital e-flyer, as well as read and accept the Contest Rules, privacy policy, and comply with the entry requirements of these Contest Rules to be
                            entered for your chance to win (an “Online Entry”). Each entrant can earn one (1) bonus entry by providing your Scene+ card number when completing the
                            Entry Form (a “Bonus Entry” and together with the Online Entry, an “Entry(ies)”). By opting-in to receiving communications, you are providing your
                            express consent to receive electronic marketing and promotional communications from Sponsor. You may opt-out at any time. An internet connection using
                            data or Wi-Fi is required to complete the steps above.
                        </p>
                        <p>Limit of one (1) Online Entry and one (1) Bonus Entry, per e-mail address, per person, during the Contest Period.</p>
                        <ol start={4}>
                            <li>
                                <strong>Prize Description</strong>
                            </li>
                        </ol>
                        <p>
                            There are two (2) prizes to be won throughout the entire Contest Period. See Section 2 for the quantity of prizes available during each Draw Period.
                            Each prize consists of $1,000 in Ticketmaster® Gift Cards (each a “Gift Card”), (each a “Prize(s)”).
                        </p>
                        <p>
                            <br /> The following conditions apply to the Prizes:
                        </p>
                        <ul>
                            <li>
                                The Prizes have no equivalent cash value, are non-transferable and no substitutions will be made. Prize must be accepted as awarded. The Sponsor
                                reserves the right to substitute any Prize for one of equal or greater value for any reason. You are responsible for all costs not expressly
                                described as being included as part of the Prize, such as any fee associated with the receipt and/or use of the Prize.
                            </li>
                            <li>You must sign the Sponsor’s release form (the “Release”) in order to receive and participate in the Prize.</li>
                            <li>Gift Card increments will be allocated at the Sponsor’s discretion.</li>
                            <li>
                                Gift cards may be used toward the purchase of tickets sold through the Live Nation (“LN”) and Ticketmaster® websites and in the Ticketmaster® mobile
                                app in the United States and Canada. Gift cards cannot be used toward VIP Nation<sup>®</sup>,, fan clubs, other gift cards and select ticket
                                packages. Exclusions may apply to TM+, Platinum, Fan-to-Fan Resale, TicketExchange by Ticketmaster® and other tickets not sold directly by
                                Ticketmaster®. Other exclusions may apply. Gift cards cannot be combined with other Gift cards, gift certificates or other coupons. Gift cards may
                                not be used in connection with any marketing, advertising or promotional activities without LN’s prior written approval and may not be transferred
                                or sold other than through authorized Live Nation channels. Live Nation reserves the right to cancel a Gift card if it believes that the Gift card
                                was obtained through fraudulent or unauthorized means. If the Gift card holder’s purchase exceeds the amount of that Gift card’s balance, the Gift
                                card holder must pay the difference by another means. Unused Gift card balances are not transferable. Gift cards are not returnable or redeemable
                                for cash except in States or Provinces where it is required by law. Additional Gift card terms found{" "}
                                <a href="https://help.ticketmaster.com/hc/en-us/articles/9663455441041-Gift-Card-Terms-of-Use?language=en_US">here</a> apply, which are subject to
                                change at any time without notice. The use of Gift cards on the LN and Ticketmaster® websites are subject to the Terms of Use and Privacy Policy on
                                each site.
                            </li>
                            <li>Any difference between approximate retail value and the actual value of the Prizes as taken will not be awarded.</li>
                        </ul>
                        <ol start={5}>
                            <li>
                                <strong>Odds</strong>
                            </li>
                        </ol>
                        <p>
                            The odds of winning a Prize depends on the total number of eligible Entries received during each Draw Period throughout the duration of the Contest
                            Period.
                        </p>
                        <ol start={6}>
                            <li>
                                <strong>Draw Details</strong>
                            </li>
                        </ol>
                        <p>
                            Random draws will be conducted by a representative or agent of the Sponsor located in Ontario, Canada, from all eligible Entries received during the
                            Contest Period (the “Draw”). The Draws will occur in accordance with the Prize chart in Section 2. You do not need to be in attendance to be selected.
                            You are only eligible to win a Prize once.
                        </p>
                        <p />
                        <ol start={7}>
                            <li>
                                <strong>Winner Contact Details</strong>
                            </li>
                        </ol>
                        <p>
                            If you are chosen to be eligible to win a Prize, you will be contacted within fourteen (14) business days of the Draw at the telephone number, email
                            address or using the contact information that was submitted in the Entry. If you have not provided a telephone number or email address, you are not
                            eligible to win the Prize.&nbsp;In order to be declared a winner, you must correctly answer without assistance a mathematical skill testing question and
                            sign a Release form. Upon verification of the completion and correctness of the answer to the skill-testing question, and execution of the Release, you
                            may be declared a winner. In case of a dispute concerning the identity of the individual who submitted an Entry, the entrant will be deemed to be the
                            account holder of the email address under which the Entry was submitted.
                        </p>
                        <ol start={8}>
                            <li>
                                <strong>Forfeit of Prize</strong>
                            </li>
                        </ol>
                        <p>
                            If you cannot be reached within forty-eight (48) hours of our first attempt to contact you, do not answer the skill testing question correctly, or
                            otherwise fail to comply with these Contest Rules, then the Prize will be forfeited and another Entry will be randomly selected from among the remaining
                            eligible Entries as per these Contest Rules. This process of selecting a Prize winner will be repeated until all Prizes eligible to be won have been
                            awarded.
                        </p>
                        <ol start={9}>
                            <li>
                                <strong>Use of Personal Information</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest and voluntarily providing your personal information as described in these Contest Rules, you agree to the collection, use and
                            disclosure by the Administrator, and its respective employees and/or authorized agents, of your personal information, for the purpose of administering
                            the Contest, including—but not limited to—contacting you with respect to the Contest if you are a selected entrant. Personally-identifiable information
                            will only be used to administer the Contest and for no other purpose, except as specifically outlined in these Contest Rules. Personally-identifiable
                            information will not be sold, shared or disclosed by the Administrator to any third party, other than to a third party engaged by the Administrator for
                            the purpose of administering the Contest, or if required by law with the exception that aggregated information may be used by or shared amongst the
                            Administrator and its subsidiaries and affiliates, or shared by the Administrator with third parties in accordance with our Privacy Commitment available
                            at https://www.sobeys.com/en/privacy-policy.
                        </p>
                        <ol start={10}>
                            <li>
                                <strong>Publicity Consent</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest, you agree that if you are determined to be a winner, the Administrator and the Sponsor may use your name, comments, voice,
                            likeness, municipality of residence, your contest Entry and photo submission and picture in any advertising, promotion or publicity carried out now or
                            in the future, in any media without compensation or notice, and you grant to the Administrator and the Sponsor any and all rights to such use. You agree
                            to cooperate with the Administrator and the Sponsor in arranging for photographs or other forms of publicity and to be available for photographs or
                            other forms of publicity on reasonable notice.
                        </p>
                        <ol start={11}>
                            <li>
                                <strong>Limitation of Liability / Release</strong>
                            </li>
                        </ol>
                        <p>
                            By participating in the Contest, and as a condition of acceptance of the Prize, you, your heirs, executors, administrators, successors and assigns,
                            release and forever discharge and hold harmless the Sponsor and the Administrator, Live Nation Canada, Inc., (“Live Nation”); Ticketmaster Canada Ltd,
                            Ticketmaster, LLC (collectively “Ticketmaster”), and each of their direct and indirect parent companies, affiliates, subsidiaries, successors, assigns,
                            agents, advisors, franchisees, shareholders, partners, representatives, their advertising, promotion and fulfillment agencies and each of their
                            respective employees, officers, directors, agents and representatives (collectively, the “Released Parties”), from and against any and all losses,
                            damages (including, without limitation, direct, indirect, incidental, consequential or punitive damages), rights, claims, actions, causes of action,
                            personal injury, property damage or death, including without limitation all costs and liabilities of any kind including legal fees on a substantial
                            indemnity scale, hereinafter called “Claims” and including without limitation any claims arising from any act of negligence of the Released Parties,
                            that you now have, or may hereafter have against the Released Parties directly or indirectly resulting or arising from: (i) participation in the
                            Contest, your Entry and/or the awarding, acceptance, possession, use, or misuse or enjoyment of any Prize, and where applicable, traveling to, preparing
                            for, or participating in, any Contest-related or Prize-related event or activity; or (ii) the publicity rights granted to the Sponsor and the
                            Administrator. You specifically acknowledge that you understand that there may be serious risks of bodily injury, death, or property damage associated
                            with the acceptance, possession, use and/or misuse of the Prize and attendance at any event or participation in certain Prize-related activities, and
                            you voluntarily assume these risks. You also agree to indemnify, defend, and hold harmless the Released Parties from any and all Claims in respect of
                            the foregoing.
                        </p>
                        <ol start={12}>
                            <li>
                                <strong>Compliance with Rules</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest, you agree to abide by the entire Contest Rules and the terms and conditions under which the Prizes are awarded. Any decision
                            made by the Sponsor and/or the Administrator in respect of this Contest shall be final.
                        </p>
                        <ol start={13}>
                            <li>
                                <strong>Technical Issues</strong>
                            </li>
                        </ol>
                        <p>
                            The Sponsor and Administrator are not responsible for any computer, online, telephone, hardware, software or technical limitations or malfunctions that
                            may occur (including but not limited to malfunctions that may affect the transmission or non-transmission of an Entry, or failure to receive an Entry),
                            nor for any incorrect or inaccurate information, whether caused by website users or by any of the equipment or programming associated with or utilized
                            in the Contest or by any technical or human error which may occur in the processing of Entries in the Contest, nor for any error, omission,
                            interruption, deletion, defect, delay in operation or transmission or receipt of Entry, communications line failure, theft or destruction or
                            unauthorized access to, or alteration of Entries; and, are not responsible for any problems, failures or technical malfunction of any telephone or
                            network lines, computer online systems, servers, providers, computer equipment, software, email, players, or browsers, on account of technical problems
                            or traffic congestion on the Internet, at any website or on account of any combination of the foregoing; and are not responsible for any injury or
                            damage to entrants or to any computer related injury or damage resulting from participating in downloading materials in this Contest.
                        </p>
                        <p>
                            In the event that, due to a printing, manufacturing, mechanical or other error of any kind or nature whatsoever, more Prizes are claimed than are
                            intended to be awarded according to these Contest Rules, Prizes will stop being awarded when the Administrator becomes aware of an error respecting the
                            number of Prizes and the Administrator reserves the right, in their sole and absolute discretion, to conduct a random draw from amongst all eligible and
                            approved Prize claimants whose claims have not yet been redeemed to award the remaining number of advertised Prizes. In no event will the Sponsor or
                            Administrator be liable for more than the stated number of Prizes contained in these Contest Rules.
                        </p>
                        <p> </p>
                        <ol start={14}>
                            <li>
                                <strong>Rights to Terminate/Modify/Suspend Contest</strong>
                            </li>
                        </ol>
                        <p>
                            The Administrator and Sponsor retain the right, in their absolute discretion, to suspend, modify or terminate any aspect of the Contest (including, but
                            not limited to these Contest Rules) at any time. The Administrator and Sponsor may make substitutions of equivalent kind or value in the event of the
                            unavailability of a Prize (or component thereof) for any reason whatsoever. The Administrator and Sponsor may terminate or withdraw this Contest at any
                            time by posting a notice on our website. If the Contest is terminated on any day prior to the normal Contest end date (the “Termination Date”), the
                            Administrator and Sponsor may determine the winners from all eligible Entries received as of 12:00 p.m. ET on the Termination Date (which winners shall
                            be randomly selected in accordance with these Contest Rules).{" "}
                        </p>
                        <ol start={15}>
                            <li>
                                <strong>General Disqualification</strong>
                            </li>
                        </ol>
                        <p />
                        <p>
                            Any attempt made by an entrant or other individual or entity, to deliberately damage, manipulate or undermine the legitimate operation of the Contest,
                            including but not limited to any fraudulent claims, is a violation of the law. The Administrators and Sponsor reserve the right to seek remedies and
                            damages from any such individual or entity which makes any such attempt. Any entrant who, in the opinion of the Administrators, is determined to be
                            engaging in any of the foregoing activities will be immediately disqualified from the Contest.
                        </p>
                        <ol start={16}>
                            <li>
                                <strong>Role of Parties</strong>
                            </li>
                        </ol>
                        <p>Sobeys Capital Incorporated is the Sponsor and XMC Experiential Marketing is the Administrator of this Contest.</p>
                        <p>
                            Live Nation and Ticketmaster are not Sponsors of this Contest and in no way responsible for the administration of this Contest, the selection or
                            verification of winners, or the fulfillment of any Prize. All inquiries regarding this Contest should be directed to the Sponsor and Administrator.
                        </p>
                        <p> </p>
                        <ol start={17}>
                            <li>
                                <strong>Trade-marks</strong>
                            </li>
                        </ol>
                        <p>All other Trade-marks are the property of their respective owners.</p>
                        <p>Live Nation, Ticketmaster, VIP Nation, TM+, TicketExchange by Ticketmaster are trademarks of Live Nation Entertainment, Inc.</p>
                        <ol start={18}>
                            <li>
                                <strong>Jurisdiction</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest is governed by the laws of Ontario and the laws of Canada applicable therein, and is subject to all applicable federal, provincial and
                            municipal laws and regulations and is void where prohibited by law. Participation constitutes your full and unconditional agreement to these Contest
                            Rules and the decisions of the Sponsor and Administrator, which are final and binding in all matters related to the Contest. By entering the Contest,
                            you agree that the courts of Ontario, Canada shall have jurisdiction to entertain any action or other legal proceedings based on any provision of this
                            Contest’s Rules or the running of this Contest.
                        </p>
                    </>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Rules;
