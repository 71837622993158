import { FunctionComponent } from "react";

interface Props {}

const Thankyou: FunctionComponent<Props> | any = () => {


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12" style={{ height: "36em", display: "flex", alignItems: "center" }}>
                    <div className="content" style={{ textAlign: "center" }}>
                        <img src="assets/images/img-tick.png" alt="tick" className="tickImg" />
                        <h1 style={{ marginBottom: "30px" }}>Thank You</h1>
                        <p className="thanks_head">Thank you for participating!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Thankyou;
